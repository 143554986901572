import { useState, useMemo, useEffect, useRef } from 'react';
import { Button, Flex, Heading } from 'theme-ui';
import { SizeGuide } from '@templates/product/Main/Details/Fit/SizeGuide';
import { colors } from '@theme/theme.colors';

export const QuickAddWaistInseam = ({
  theme,
  variants,
  selectedProduct,
  handleQuickAdd,
}) => {
  const [waistOptions, inseamOptions] = selectedProduct?.grouping.options || [];
  const [waistValue, setWaistValue] = useState(null);
  const [inseamValue, setInseamValue] = useState(null);
  const [smallContainer, setSmallContainer] = useState(false);
  const containerRef = useRef(null);

  const variantMap = useMemo(() => {
    const map = {};
    variants.forEach((variant) => {
      const waist = variant.selectedOptionsMap.Waist;
      const inseam = variant.selectedOptionsMap.Inseam;
      if (!map[waist]) {
        map[waist] = {};
      }
      map[waist][inseam] = variant;
    });
    return map;
  }, [variants]);

  const waistAvailability = useMemo(() => {
    const availability = {};
    waistOptions.values.forEach((waist) => {
      if (inseamValue) {
        availability[waist] =
          variantMap[waist]?.[inseamValue]?.availableForSale || false;
      } else {
        availability[waist] = Object.values(variantMap[waist] || {}).some(
          (variant) => variant.availableForSale
        );
      }
    });
    return availability;
  }, [variantMap, inseamValue, waistOptions]);

  const inseamAvailability = useMemo(() => {
    const availability = {};
    inseamOptions.values.forEach((inseam) => {
      if (waistValue) {
        availability[inseam] =
          variantMap[waistValue]?.[inseam]?.availableForSale || false;
      } else {
        availability[inseam] = variants.some(
          (variant) =>
            variant.selectedOptionsMap.Inseam === inseam &&
            variant.availableForSale
        );
      }
    });
    return availability;
  }, [variantMap, waistValue, inseamOptions, variants]);

  const selectedVariant = useMemo(() => {
    if (waistValue && inseamValue) {
      return variantMap[waistValue]?.[inseamValue];
    }
    return null;
  }, [variantMap, waistValue, inseamValue]);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const { width } = containerRef.current.getBoundingClientRect();
        setSmallContainer(width < 220);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSelection = () => {
    if (selectedVariant && selectedVariant.availableForSale) {
      handleQuickAdd(selectedVariant);
    }
  };

  const buttonStyles = {
    margin: '0px',
    display: 'inline-block',
    textAlign: 'center',
    lineHeight: 1,
    fontSize: '10px',
    letterSpacing: '1px',
    borderRadius: 0,
    transition: 'color 0s ease 0s, background-color 300ms ease 0s',
    px: 2,
    flex: 1,
    alignSelf: 'stretch',
    minWidth: '55.8px',
    flexGrow: 0,
    '@media screen and (min-width: 480px)': {
      '&[disabled]': {
        ':hover': {
          cursor: 'not-allowed',
          bg: colors.gray.mediumLight,
        },
      },
    },
  };

  return (
    <Flex
      sx={{
        ...theme.sizeContainer,
        opacity: 1.0,
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginBottom: '1rem',
        backgroundColor: 'white',
        margin: '0 auto',
        height: 'auto',
        width: '100%',
        gap: '1.5rem',
        p: 4,
        transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
        bottom: '0',
        top: 'auto',
        ':hover': {
          opacity: 1.0,
        },
      }}
    >
      {/* Waist Options */}
      <Flex sx={{ flexDirection: 'column', gap: 2, width: '100%' }}>
        <Flex sx={{ justifyContent: 'space-around' }}>
          <Heading
            as="h3"
            sx={{
              color: 'text',
              textTransform: 'uppercase',
              fontSize: '11px',
              fontWeight: '500',
            }}
          >
            Pant Waist {waistValue}
          </Heading>
          <SizeGuide sizeTag="bottoms" styles={{ color: 'black' }} />
        </Flex>
        <Flex
          sx={{
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            width: '100%',
            alignItems: 'stretch',
          }}
        >
          {waistOptions?.values.map((waist, index) => {
            const isDisabled = !waistAvailability[waist];
            const isSelected = waistValue === waist;
            return (
              <Button
                key={index}
                aria-label={`Select Waist: ${waist}`}
                disabled={isDisabled}
                variant="buttons.secondary"
                onClick={() => setWaistValue(waist)}
                sx={{
                  ...buttonStyles,
                  width: '100%',
                  color: isDisabled ? colors.white : colors.black,
                  bg: isDisabled ? colors.gray.mediumLight : colors.white,
                  border: isSelected
                    ? '1px solid #000'
                    : `0.25px solid ${colors.gray.mediumLight}`,
                }}
              >
                {waist}
              </Button>
            );
          })}
        </Flex>
      </Flex>

      {/* Inseam Options */}
      <Flex sx={{ flexDirection: 'column', gap: 2, width: '100%' }}>
        <Heading
          as="h3"
          sx={{
            color: 'text',
            textTransform: 'uppercase',
            fontSize: '11px',
            fontWeight: '500',
          }}
        >
          Inseam {inseamValue}
        </Heading>
        <Flex
          ref={containerRef}
          sx={{
            justifyContent: 'space-around',
            width: '100%',
            flexDirection: smallContainer ? 'column' : 'row',
            gap: smallContainer ? 4 : 0,
          }}
        >
          <Flex
            sx={{
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
              width: '100%',
              alignItems: 'stretch',
            }}
          >
            {inseamOptions?.values.map((inseam, index) => {
              const isDisabled = !inseamAvailability[inseam];
              const isSelected = inseamValue === inseam;
              return (
                <Button
                  key={index}
                  aria-label={`Select Inseam: ${inseam}`}
                  disabled={isDisabled}
                  variant="buttons.secondary"
                  onClick={() => setInseamValue(inseam)}
                  sx={{
                    ...buttonStyles,
                    width: '55.8px',
                    color: isDisabled ? colors.white : colors.black,
                    bg: isDisabled ? colors.gray.mediumLight : colors.white,
                    border: isSelected
                      ? '1px solid #000'
                      : `0.25px solid ${colors.gray.mediumLight}`,
                  }}
                >
                  {inseam}
                </Button>
              );
            })}
          </Flex>
          <Button
            onClick={handleSelection}
            disabled={!waistValue || !inseamValue}
            sx={{
              backgroundColor:
                waistValue && inseamValue
                  ? colors.black
                  : colors.gray.mediumLight,
              color: colors.white,
              '&:hover': {
                cursor: !waistValue || !inseamValue ? 'not-allowed' : 'pointer',
                color: waistValue && inseamValue ? colors.black : colors.white,
                backgroundColor:
                  waistValue && inseamValue
                    ? colors.white
                    : colors.gray.mediumLight,
              },
              p: 0,
              minWidth: '60px',
              width: '100%',
              '@media screen and (max-width: 600px)': {
                p: 0,
              },
            }}
          >
            Add to Cart
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
