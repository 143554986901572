import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      pb: 10,
      // pt: [15, 18],
      color: 'white',
      bg: 'black',
      minHeight: '250px',
    };

    this.content = {
      variant: 'layout.content',
      px: [10, 12, 14],
      maxWidth: '1360px',
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridGap: [15, 18],
    };

    this.grid = {
      width: '100%',
      gridTemplateColumns: ['1fr', null, '1fr auto'],
      gridTemplateAreas: [' "menu" "email"', null, '"menu email"'],
      rowGap: 15,
    };

    this.socialLinks = {
      mx: '2rem',
    };
    this.heading = {
      variant: 'text.h1',
    };
    this.subheading = {
      variant: 'text.h5',
    };
    this.superheading = {
      variant: 'text.h4',
    };
  })()
);
