
import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.badgeWrapper = {
      display: 'flex',
      position: 'absolute',
      zIndex: 1,
      width: 'fit-content',
      justifyContent: 'flex-start',
    };

    this.badge = (fabric = '') => {
      let icon = '';
      switch (fabric) {
        case 'pyca pro':
          icon = '"\\00AE"';
          break;
        case 'versaknit':
        case 'tomboy cotton':
          icon = '"\\2122"';
          break;
        default:
          icon = '""';
          break;
      }
      return {
        px: [3, 5],
        mr: [3, 5],
        minWidth: [0, 'auto'],
        border: 'none',
        fontSize: ['8px', '10px'],
        ':after': {
          content: `${icon}`,
        },
      };
    };

    this.saleBadge = {
      px: '20px',
      minWidth: [0, 'auto'],
      border: 'none',
      fontSize: ['8px', '10px'],
      bg: 'red',
      borderRadius: '20px',
    };

    this.preOrder = {
      px: '20px',
      verticalAlign: 'middle',
      height: '100%',
      py: '5px',
      minWidth: [0, 'auto'],
      border: 'none',
      fontSize: ['8px', '12px'],
      borderRadius: '20px',
    };
  })()
);
