import { Box, Flex } from 'theme-ui';

export const TimeUnitDisplay = ({
  timeValue,
  label,
  showColon,
  colonColor,
}) => (
  <>
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          fontSize: '30px',
          fontWeight: '500',
          fontFamily: 'Roboto Condensed',
          lineHeight: '35.16px',
          letterSpacing: '-0.02em',
          textAlign: 'left',
        }}
      >
        {timeValue.toString().length === 1 ? `0${timeValue}` : timeValue}
      </Box>
      <Box
        as="span"
        sx={{
          fontSize: '9px',
          fontFamily: 'Roboto',
          fontWeight: '500',
          lineHeight: '10.55px',
          letterSpacing: '-0.02em',
          textAlign: 'center',
        }}
      >
        {label}
      </Box>
    </Flex>
    {showColon && (
      <Box
        sx={{
          fontSize: '45px',
          px: '10px',
          color: colonColor || '#505050',
          lineHeight: '16px',
        }}
      >
        :
      </Box>
    )}
  </>
);
