import { align, color, layout, margin, padding } from '@settings/common';

// text settings
const heading = {
  label: 'Heading Settings',
  name: 'heading',
  description: 'Heading text, position and color',
  component: 'group',
  fields: [
    {
      name: 'headingText',
      component: 'markdown',
      label: 'Heading',
      description:
        'Ensure heading is "h1" by starting with "# " e.g. "# Shop All". Text below will become large and bold to indicate "h1".',
    },

    {
      component: 'select',
      name: 'headingColorMb',
      label: 'Text Color (mobile)',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
    // {
    //   name: 'headingAlignmentDt',
    //   label: 'Heading Alignment (desktop)',
    //   component: 'radio-group',
    //   direction: 'horizontal',
    //   variant: 'radio',
    //   options: align.options,
    // },
    {
      component: 'select',
      name: 'headingColorDt',
      label: 'Text Color (tablet & desktop)',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
    {
      name: 'fontSize',
      label: 'Font Size',
      component: 'select',
      options: [
        {
          label: 'Default',
          value: '',
        },
        {
          label: 'xs',
          value: 0,
        },
        {
          label: 'sm',
          value: 1,
        },
        {
          label: 'base',
          value: 2,
        },
        {
          label: 'md',
          value: 3,
        },
        {
          label: 'lg',
          value: 4,
        },
        {
          label: 'xl',
          value: 5,
        },
        {
          label: '2xl',
          value: 6,
        },
        {
          label: '3xl',
          value: 7,
        },
        {
          label: '4xl',
          value: 8,
        },
        {
          label: '5xl',
          value: 9,
        },
        {
          label: '6xl',
          value: 10,
        },
      ],
    },
  ],
  defaultValue: {
    headingText: '# ANNUAL SUMMER SALE',
    // headingAlignmentMb: 'center',
    headingColorMb: '#000',
    // headingAlignmentDt: 'center',
    headingColorDt: '#000',
  },
};

const subheading = {
  label: 'SubHeading Settings',
  name: 'subheading',
  description: 'SubHeading text, position and color',
  component: 'group',
  fields: [
    {
      name: 'subheadingText',
      component: 'markdown',
      label: 'SubHeading',
      description: 'This heading is below the main heading.',
    },
    // {
    //   name: 'subheadingAlignmentMb',
    //   label: 'Subheading Alignment (mobile)',
    //   component: 'radio-group',
    //   direction: 'horizontal',
    //   variant: 'radio',
    //   options: align.options,
    // },
    {
      component: 'select',
      name: 'subheadingColorMb',
      label: 'Text Color (mobile)',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
    // {
    //   name: 'subheadingAlignmentDt',
    //   label: 'Subheading Alignment (desktop)',
    //   component: 'radio-group',
    //   direction: 'horizontal',
    //   variant: 'radio',
    //   options: align.options,
    // },
    {
      component: 'select',
      name: 'subheadingColorDt',
      label: 'Text Color (tablet & desktop)',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
    {
      name: 'fontSize',
      label: 'Font Size',
      component: 'select',
      options: [
        {
          label: 'Default',
          value: '',
        },
        {
          label: 'xs',
          value: 0,
        },
        {
          label: 'sm',
          value: 1,
        },
        {
          label: 'base',
          value: 2,
        },
        {
          label: 'md',
          value: 3,
        },
        {
          label: 'lg',
          value: 4,
        },
        {
          label: 'xl',
          value: 5,
        },
        {
          label: '2xl',
          value: 6,
        },
        {
          label: '3xl',
          value: 7,
        },
        {
          label: '4xl',
          value: 8,
        },
        {
          label: '5xl',
          value: 9,
        },
        {
          label: '6xl',
          value: 10,
        },
      ],
    },
  ],
  defaultValue: {
    subheadingText: '',
    subheadingAlignmentMb: 'center',
    subheadingColorDt: '#000',
    subheadingAlignmentDt: 'center',
    subheadingColorMb: '#000',
  },
};

const expired = {
  label: 'Expired text Settings',
  name: 'expired',
  description: 'expired text, position and color',
  component: 'group',
  fields: [
    {
      name: 'expiredText',
      component: 'markdown',
      label: 'expired',
      description:
        'Ensure expired is "h1" by starting with "# " e.g. "# Shop All". Text below will become large and bold to indicate "h1".',
    },
    // {
    //   name: 'expiredAlignmentMb',
    //   label: 'expired Alignment (mobile)',
    //   component: 'radio-group',
    //   direction: 'horizontal',
    //   variant: 'radio',
    //   options: align.options,
    // },
    {
      component: 'select',
      name: 'expiredColorMb',
      label: 'Text Color (mobile)',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
    // {
    //   name: 'expiredAlignmentDt',
    //   label: 'expired Alignment (desktop)',
    //   component: 'radio-group',
    //   direction: 'horizontal',
    //   variant: 'radio',
    //   options: align.options,
    // },
    {
      component: 'select',
      name: 'expiredColorDt',
      label: 'Text Color (tablet & desktop)',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
    {
      name: 'fontSize',
      label: 'Font Size',
      component: 'select',
      options: [
        {
          label: 'Default',
          value: '',
        },
        {
          label: 'xs',
          value: 0,
        },
        {
          label: 'sm',
          value: 1,
        },
        {
          label: 'base',
          value: 2,
        },
        {
          label: 'md',
          value: 3,
        },
        {
          label: 'lg',
          value: 4,
        },
        {
          label: 'xl',
          value: 5,
        },
        {
          label: '2xl',
          value: 6,
        },
        {
          label: '3xl',
          value: 7,
        },
        {
          label: '4xl',
          value: 8,
        },
        {
          label: '5xl',
          value: 9,
        },
        {
          label: '6xl',
          value: 10,
        },
      ],
    },
  ],
  defaultValue: {
    expiredText: '# WE ARE NOW IN OUR ANNUAL SUMMER SALE',
    // expiredAlignmentMb: 'center',
    expiredColorMb: '',
    // expiredAlignmentDt: 'center',
    expiredColorDt: '',
  },
};

const text = {
  label: 'Text Settings',
  name: 'text',
  description: 'Heading, Subheading, Color',
  component: 'group',
  fields: [heading, subheading, expired],
};

// section settings
const fields = [
  {
    name: 'bgColor',
    label: 'Background Color',
    component: 'select',
    options: color.options,
  },
  {
    name: 'contentWidth',
    component: 'number',
    label: 'Max Content Pixel Width',
  },
  {
    name: 'signupWidth',
    component: 'number',
    label: 'Max Signup Form Pixel Width',
  },
];

const mobileFields = [
  {
    label: 'Container Width (mobile)',
    component: 'select',
    name: 'container',
    options: layout.options,
  },
  {
    name: 'mt',
    label: 'Margin Top (mobile)',
    component: 'select',
    options: margin.options,
  },
  {
    name: 'py',
    label: 'Vertical Padding (mobile)',
    component: 'select',
    options: padding.options,
  },
];

const desktopFields = [
  {
    label: 'Container Width (desktop)',
    component: 'select',
    name: 'container',
    options: layout.options,
  },
  {
    name: 'mt',
    label: 'Margin Top (desktop)',
    component: 'select',
    options: margin.options,
  },
  {
    name: 'py',
    label: 'Vertical Padding (desktop)',
    component: 'select',
    options: padding.options,
  },
];

const mobile = {
  label: 'Mobile Settings',
  name: 'mobile',
  component: 'group',
  fields: mobileFields,
};

const desktop = {
  label: 'Desktop Settings',
  name: 'desktop',
  component: 'group',
  fields: desktopFields,
};

const section = {
  label: 'Section Settings',
  name: 'section',
  description:
    'Background color, container width, margin top, vertical padding',
  component: 'group',
  fields: [...fields, desktop, mobile],
};

export const Schema = () => {
  return {
    label: 'Countdown Timer Banner',
    key: 'countdown-timer-banner',
    fields: [
      text,
      {
        label: 'Image Background',
        name: 'image',
        component: 'image',
      },
      {
        label: 'Height',
        name: 'height',
        component: 'number',
        description: 'Height of the banner',
        defaultValue: 10,
      },
      {
        label: 'Start & End DateTimes',
        name: 'dateTimes',
        component: 'group',
        fields: [
          {
            label: 'End Date',
            name: 'endDate',
            component: 'date',
            description:
              'Date automatically ends. Refresh page to observe update',
            dateFormat: true,
            defaultValue: '2022-01-02T00:00:00Z',
          },
          {
            label: 'End Time (Pacific Time)',
            name: 'endTime',
            component: 'number',
            description:
              '24-hour format. Add minutes as decimals. e.g. "14.30" equals 2:30pm. Refresh page to observe update',
            defaultValue: 12.0,
          },
          {
            label: 'color',
            name: 'color',
            component: 'color',
            defaultValue: '#fff',
          },
          {
            label: 'background',
            name: 'background',
            component: 'color',
            defaultValue: '#484848',
          },
          {
            label: 'colon color',
            name: 'colon',
            component: 'color',
            defaultValue: '#505050',
          },
          {
            label: 'border color',
            name: 'border',
            component: 'color',
            defaultValue: '#C1C1C1',
          },
        ],
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          contentWidth: '600px',
          signupWidth: '400px',
          mobile: {
            container: 'container',
            mt: 'none',
            py: 's',
          },
          desktop: {
            container: 'container',
            mt: 'none',
            py: 's',
          },
        },
      },
    ],
  };
};
