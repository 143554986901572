import { useSettings } from '@backpackjs/storefront';
import { useBreakpointValue } from '@hooks';
import { Markdown } from '@snippets';
import { Flex, Image } from 'theme-ui';

export const FooterImage = ({ theme }) => {
  const { footer } = useSettings() || {};

  const {
    heading,
    subheading,
    superheading,
    image,
    imageHeight,
    imageHeightMb,
    enable,
  } = footer?.imageSettings || {};

  const headingText = heading?.headingText;
  const headingTextAlign = useBreakpointValue([
    heading?.headingAlignmentMb,
    heading?.headingAlignmentDt,
  ]);
  const headingColor = useBreakpointValue([
    heading?.headingColorMb || 'currentColor',
    heading?.headingColorDt || 'currentColor',
  ]);

  const subheadingText = subheading?.subheadingText;
  const subheadingTextAlign = useBreakpointValue([
    subheading?.subheadingAlignmentMb,
    subheading?.subheadingAlignmentDt,
  ]);
  const subheadingColor = useBreakpointValue([
    subheading?.subheadingColorMb || 'currentColor',
    subheading?.subheadingColorDt || 'currentColor',
  ]);

  const superheadingText = superheading?.superheadingText;
  const superheadingTextAlign = useBreakpointValue([
    superheading?.superheadingAlignmentMb,
    superheading?.superheadingAlignmentDt,
  ]);
  const superheadingColor = useBreakpointValue([
    superheading?.superheadingColorMb || 'currentColor',
    superheading?.superheadingColorDt || 'currentColor',
  ]);

  return (
    <Flex
      sx={{
        position: 'relative',
        flexDirection: 'column',
        mb: 16,
        minHeight: enable ? [250, 300] : 0,
        height: enable ? [imageHeightMb, imageHeight] : 0,
        width: '100%',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      {enable && (
        <>
          <Image
            src={image?.url}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
          {superheadingText && (
            <Markdown
              text={superheadingText}
              textSx={{
                ...theme?.superheading,
                textAlign: superheadingTextAlign,
                color: superheadingColor,
              }}
              sx={{
                mb: 8,
                position: 'relative',
              }}
            />
          )}

          <Markdown
            text={headingText}
            textSx={{
              ...theme.heading,
              textAlign: headingTextAlign,
              color: headingColor,
            }}
            sx={{
              mb: [8, 11],
              position: 'relative',
            }}
          />

          {subheadingText && (
            <Markdown
              text={subheadingText}
              textSx={{
                ...theme?.subheading,
                textAlign: subheadingTextAlign,
                color: subheadingColor,
              }}
              sx={{
                position: 'relative',
              }}
            />
          )}
        </>
      )}
    </Flex>
  );
};
