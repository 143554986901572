import { Button, Flex } from 'theme-ui';

export const QuickAddSize = ({
  theme,
  variants,
  selectedVariant,
  setSelectedVariant,
  addToCart,
  inventory,
  inventoryFetched,
  handleQuickAdd,
}) => {
  return (
    <Flex sx={theme.sizeContainer}>
      {variants.map((variant, index) => {
        const isLast = index === variant.length - 1;
        const size =
          variant.selectedOptionsMap.Size ||
          variant.selectedOptionsMap.Amount ||
          variant.selectedOptionsMap.Waist;
        const variantInventory = inventory?.variants?.[variant?.id];
        const variantIsSoldOut =
          inventoryFetched && !variantInventory?.availableForSale;

        return (
          <Button
            key={index}
            aria-label={`Select Size: ${size}`}
            disabled={variantIsSoldOut}
            variant="buttons.secondary"
            onClick={() => handleQuickAdd(variant)}
            sx={theme.button({ isLast, isDisabled: variantIsSoldOut })}
          >
            {size || 'Add to Cart'}
          </Button>
        );
      })}
    </Flex>
  );
};
