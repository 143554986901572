import { useCallback } from 'react';
import { Box } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

import { StatefulButton, BackInStockModal } from '@snippets';
import { useModal } from '@hooks';

import { VipProduct } from '@snippets/VipProduct';
import { useAddToCart } from './useAddToCart';
import { themed } from './AddToCart.theme';

export const AddToCart = themed(
  ({
    theme,
    style = {
      wrapper: {},
      button: {},
    },
    product,
    selectedVariant,
    title,
    attributes,
    hasDisclaimer = false,
    inputRef,
    buttonText = 'Add to Cart',
    isVip = false,
    ...props
  }) => {
    const [{ status, isPreOrder, isSoldOut, selectedPlan }, { addToCart }] =
      useAddToCart({ product, selectedVariant });
    const { openModal, closeModal } = useModal()[1];
    const settings = useSettings();
    const bisButtonText = settings?.product?.backInStock?.buttonText;
    const { preOrderCta, enabled: isPreOrderActive } =
      settings?.product?.preOrder || {};
    const isMembership = product?.tags?.includes('inveterate-product');
    const preOrder = product?.tags?.includes('pre-order');

    const preOrderText = preOrderCta?.text || isPreOrder || 'Pre-Order*';
    const preOrderActive = preOrder && isPreOrderActive;

    const text = status?.inventory?.success
      ? preOrderActive && !isMembership
        ? preOrderText
        : isPreOrder
        ? buttonText
        : isSoldOut
        ? bisButtonText || 'Sold Out'
        : selectedPlan
        ? 'Subscribe'
        : buttonText
      : 'Loading...';

    const handleOutOfStock = useCallback(() => {
      openModal(
        <BackInStockModal
          legacyResourceId={selectedVariant?.legacyResourceId}
          title={title}
        />
      );
    }, [selectedVariant?.id]);

    const handleVipProduct = useCallback(() => {
      openModal(
        <VipProduct
          settings={settings}
          selectedVariant={selectedVariant}
          product={product}
          closeModal={closeModal}
        />,
        true
      );
    }, [openModal]);

    const handleOnClick = () => {
      const shouldHandleOutOfStock =
        (isPreOrder || isSoldOut) && !preOrderActive;

      if (shouldHandleOutOfStock) {
        handleOutOfStock();
      } else if (isVip) {
        handleVipProduct();
      } else {
        addToCart({ attributes });
      }
    };

    return (
      <Box
        data-comp={AddToCart.displayName}
        ref={inputRef}
        sx={{ ...theme.wrapper, mt: hasDisclaimer ? 0 : 8, ...style.wrapper }}
      >
        <StatefulButton
          disabled={isSoldOut}
          sx={{
            ...theme.button,
            ...(isSoldOut
              ? {
                  ...theme.button.unavailable,
                  ...(selectedPlan
                    ? theme.button.unavailable.subscribe
                    : theme.button.unavailable.oneTime),
                }
              : {
                  ...theme.button.available,
                  ...(selectedPlan
                    ? theme.button.available.subscribe
                    : theme.button.available.oneTime),
                }),
            ...(preOrderActive &&
              !isMembership && {
                backgroundColor: preOrderCta?.bgColor,
                color: preOrderCta?.textColor,
                '&:hover': {
                  backgroundColor: preOrderCta?.bgColorHover,
                },
              }),
            ...style.button,
          }}
          {...status.addItem}
          text={{
            default: text,
            started: 'Adding',
            success: isMembership ? 'Add to Cart' : 'Added', // edge case for membership
            error: text,
          }}
          onClick={handleOnClick}
          {...props}
        />
      </Box>
    );
  }
);

AddToCart.displayName = 'AddToCart';
