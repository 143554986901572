import { useCallback, useRef, useState } from 'react';
import { Container, Flex, Button, Heading, Input, Paragraph } from 'theme-ui';

import { Markdown, Section } from '@snippets';

import { Schema } from './PostscriptSubscribe.schema';

export const PostscriptSubscribe = ({ cms }) => {
  const { heading, disclaimer, section } = cms;

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const formRef = useRef();

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    const response = await fetch('https://api.postscript.io/subscribe/form', {
      method: 'POST',
      body: new URLSearchParams(new FormData(formRef.current)),
    });

    const json = await response.json();

    if (json?.error) {
      setError(json.error);
      return;
    }

    setError(null);
    setFormSubmitted(true);
  }, []);

  return (
    <Section section={section} cms={cms}>
      <Container
        data-comp={PostscriptSubscribe.displayName}
        sx={{
          maxWidth: '400px',
          mx: 'auto',
          textAlign: 'center',
        }}
      >
        {heading && (
          <Heading
            variant="text.h4"
            as="h2"
            sx={{
              textAlign: 'center',
              mb: 10,
            }}
          >
            {heading}
          </Heading>
        )}

        {formSubmitted ? (
          <Paragraph>{`Thanks for subscribing! We'll send you a message shortly.`}</Paragraph>
        ) : (
          <Flex
            variant="flex.column.center"
            as="form"
            onSubmit={handleSubmit}
            ref={formRef}
          >
            <Input
              id="shop_id"
              name="shop_id"
              sx={{
                display: 'none !important',
              }}
              type="hidden"
              value="340824"
            />

            <Input
              id="keyword_id"
              name="keyword_id"
              sx={{
                display: 'none !important',
              }}
              type="hidden"
              value="384466"
            />

            <Input
              id="phone"
              name="phone"
              placeholder="Phone Number"
              required
              type="tel"
            />

            {disclaimer && (
              <Markdown
                text={disclaimer}
                sx={{
                  fontSize: 0,
                }}
              />
            )}

            <Button
              variant="buttons.primary"
              sx={{
                mt: 10,
              }}
            >
              Subscribe
            </Button>

            {error && <Paragraph sx={{ color: 'red' }}>{error}</Paragraph>}
          </Flex>
        )}
      </Container>
    </Section>
  );
};

PostscriptSubscribe.displayName = 'PostscriptSubscribe';
PostscriptSubscribe.Schema = Schema;
