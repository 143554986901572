import { useCallback } from 'react';
import { Paragraph } from 'theme-ui';

import { useSettings, useRouter } from '@backpackjs/storefront';
import { useModal, useSelectedTags } from '@hooks';
import { SizeGuideModal } from './SizeGuideModal';

import { themed } from './SizeGuide.theme';

export const SizeGuide = themed(
  ({ theme, type, isKit, activeSize, sizeTag, styles }) => {
    const [{ modal }, { openModal, closeModal }] = useModal();
    const { isPreview } = useRouter();
    const settings = useSettings();
    const sizeGuides = settings?.product?.sizeGuides || [];

    const tags = useSelectedTags();


    const sizeGuide = isKit
      ? sizeGuides.find(({ tag, name }) => {
          return (
            tag === type?.toLowerCase() ||
            name?.toLowerCase() === type?.toLowerCase()
          );
        })
      : sizeGuides.find(({ tag }) => {
          return tag === tags?.['size-guide'];
        }) ||
        sizeGuides.find(({ tag }) => {
          return tag === sizeTag;
        });

    const toggleSizeGuideModal = useCallback(() => {
      if (modal) {
        closeModal();
        return;
      }

      openModal(<SizeGuideModal sizeGuide={sizeGuide} />);
    }, [modal, sizeGuide]);

    if (activeSize?.trim()?.replace(/\//g, '') === 'OS') return null;

    return sizeGuide?.name ? (
      <Paragraph
        data-comp={SizeGuide.displayName}
        onClick={toggleSizeGuideModal}
        sx={{ ...theme.sizeGuide, ...styles }}
        role="button"
      >
        Size Guide
      </Paragraph>
    ) : null;
  }
);

SizeGuide.displayName = 'SizeGuide';
