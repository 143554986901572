import { align, color } from '../common';

export const promoBar = {
  label: 'Promo bar',
  name: 'promoBar',
  component: 'group',
  fields: [
    {
      name: 'enabled',
      label: 'Enabled',
      component: 'toggle',
      toggleLabels: {
        true: 'Yes',
        false: 'No',
      },
      defaultValue: true,
    },
    {
      name: 'isSticky',
      label: 'Sticky',
      component: 'toggle',
      toggleLabels: {
        true: 'Yes',
        false: 'No',
      },
      defaultValue: true,
    },
    {
      name: 'closable',
      label: 'Closable',
      component: 'toggle',
      toggleLabels: {
        true: 'Yes',
        false: 'No',
      },
      defaultValue: true,
    },
    {
      component: 'number',
      name: 'duration',
      label: 'Autoplay duration',
      description: 'Time on each message. (sec)',
      step: 1,
      defaultValue: 3,
    },
    {
      component: 'number',
      name: 'speed',
      label: 'Autoplay speed',
      description: 'Time between message transitions. (sec)',
      step: 0.25,
      defaultValue: 0.75,
    },
    {
      label: 'Messages',
      name: 'messages',
      component: 'group-list',
      itemProps: {
        label: '{{item.copy}}',
      },
      defaultItem: {
        copy: '<p><b>Your</b> new promotional <i>message</i><p>',
        color: color.value.background,
        background: color.value.text,
        align: 'center',
        link: {
          url: '',
          text: '',
        },
      },
      // each message
      fields: [
        {
          label: 'Start & End DateTimes',
          name: 'dateTimes',
          component: 'group',
          description: 'Configure countdown for this message.',
          fields: [
            {
              name: 'enabled',
              label: 'Enabled',
              component: 'toggle',
              toggleLabels: {
                true: 'Yes',
                false: 'No',
              },
              defaultValue: false,
            },
            {
              label: 'Start Date',
              name: 'startDate',
              component: 'date',
              description:
                'Date kit automatically goes live. Refresh page to observe update. Scroll down further for Start Time Input',
              dateFormat: true,
              defaultValue: '2022-01-01T00:00:00Z',
            },
            {
              label: 'Start Time (Pacific Time)',
              name: 'startTime',
              component: 'number',
              description:
                '24-hour format. Add minutes as decimals. e.g. "14.30" equals 2:30pm. Refresh page to observe update',
              defaultValue: 12.0,
            },
            {
              label: 'End Date',
              name: 'endDate',
              component: 'date',
              description:
                'Date kit automatically ends. Refresh page to observe update. Scroll down further for End Time Input',
              dateFormat: true,
              defaultValue: '2022-01-02T00:00:00Z',
            },
            {
              label: 'End Time (Pacific Time)',
              name: 'endTime',
              component: 'number',
              description:
                '24-hour format. Add minutes as decimals. e.g. "14.30" equals 2:30pm. Refresh page to observe update.',
              defaultValue: 12.0,
            },
          ],
        },
        {
          label: 'Background color',
          name: 'background',
          component: 'select',
          options: color.options,
          defaultValue: color.value.text,
        },
        {
          label: 'Text color',
          name: 'color',
          component: 'select',
          options: color.options,
          defaultValue: color.value.background,
        },
        {
          label: 'Text alignment',
          name: 'align',
          component: 'select',
          options: align.options,
          defaultValue: align.value.center,
        },
        {
          name: 'locations',
          component: 'tags',
          label: 'Available Location',
          description:
            'Locations this message is enabled in. If empty, it will be available to all. Press enter to set. (Use Country ISO Code. IE: US)',
        },
        {
          label: 'Message',
          description: 'A promo bar message i.e Free shipping on orders..',
          name: 'copy',
          component: 'markdown',
        },
        {
          label: 'Message link (optional)',
          name: 'link',
          component: 'link',
          defaultValue: {
            url: '',
            text: '',
          },
        },
      ],
    },
  ],
};
