import { section } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Postscript Subscribe',
    key: 'postscript-subscribe',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
      },
      {
        label: 'Disclaimer',
        name: 'disclaimer',
        component: 'markdown',
      },
      section,
    ],
  };
};
