import { Box, Flex } from 'theme-ui';
import Countdown from 'react-countdown';
import { Markdown, Section } from '@snippets';
import { fontSizes } from '@theme/theme.fontSizes';
import { useMemo } from 'react';
import { Schema } from './CountdownTimerBanner.schema';
import { TimeUnitDisplay } from './TimeUnitDisplay';

export const CountdownTimerBanner = ({ cms }) => {
  const { dateTimes, text, section, image, height } = cms || {};
  const { endDate, color, background, colon, border, endTime } =
    dateTimes || {};

  const endDateUTC = new Date(endDate || Date.now());

  const utcOffsetPST = 7; // TODO:fix this PST during daylight saving (UTC - 7)
  const targetUTCHour = endTime + utcOffsetPST;

  endDateUTC.setUTCHours(targetUTCHour);

  const timestamp = endDateUTC.getTime();

  const isExpired = useMemo(() => {
    return timestamp < Date.now();
  }, [endDate]);

  if (!endDate) {
    return (
      <Box
        as="h2"
        variant="text.h2"
        sx={{
          fontSize: '20px',
          color: 'red',
          textAlign: 'center',
        }}
      >
        Expire Date needs to be setup{' '}
      </Box>
    );
  }
  return (
    <Section
      section={section}
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Flex
        className="countdown-container"
        sx={{
          backgroundColor: section?.bgColor || '#000',
          alignItems: 'center',
          color: '#fff',
          textAlign: 'center',
          justifyContent: 'center',
          flexDirection: ['column', 'row'],
          gap: [5, '30px'],
          py: [Number(height) || 10],
          backgroundImage: `url(${image?.url} ) `,
          backgroundSize: 'cover',
        }}
      >
        {!isExpired && (
          <Flex
            sx={{
              flexDirection: 'column',
              gap: 1,
              justifyContent: 'space-between',
            }}
          >
            <Markdown
              text={text?.heading?.headingText}
              sx={{
                textAlign: 'center',
                maxWidth: [null, '100%'],
                whiteSpace: 'pre-line',
                fontSize:
                  fontSizes[Number(text?.heading?.fontSize)] || fontSizes[6],
                color: [
                  text?.heading?.headingColorMb || '#fff',
                  text?.heading?.headingColorDt || '#fff',
                ],
                margin: 0,
                '& h1': {
                  margin: 0,
                  fontSize:
                    fontSizes[Number(text?.heading?.fontSize)] || fontSizes[6],
                },
                '& h2': {
                  margin: 0,
                  fontSize:
                    fontSizes[Number(text?.heading?.fontSize)] || fontSizes[5],
                },
                '& p': {
                  margin: 0,
                  fontSize:
                    fontSizes[Number(text?.heading?.fontSize)] || fontSizes[2],
                },
              }}
            />
            <Markdown
              text={text?.subheading?.subheadingText}
              sx={{
                textAlign: 'center',
                maxWidth: [null, '100%'],
                fontSize:
                  fontSizes[Number(text?.subheading?.fontSize)] || fontSizes[2],
                color: [
                  text?.subheading?.subheadingColorMb || '#fff',
                  text?.subheading?.subheadingColorDt || '#fff',
                ],
                margin: 0,
                '& h1': {
                  margin: 0,
                  fontSize:
                    fontSizes[Number(text?.subheading?.fontSize)] ||
                    fontSizes[2],
                },
                '& h2': {
                  margin: 0,
                  fontSize:
                    fontSizes[Number(text?.subheading?.fontSize)] ||
                    fontSizes[2],
                },
                '& p': {
                  margin: 0,
                  fontSize:
                    fontSizes[Number(text?.subheading?.fontSize)] ||
                    fontSizes[2],
                },
              }}
            />
          </Flex>
        )}
        <Countdown
          date={timestamp}
          renderer={({ days, hours, minutes, seconds, completed }) => {
            if (completed) {
              return (
                <Flex
                  sx={{
                    flexDirection: 'column',
                    gap: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Markdown
                    text={text?.expired?.expiredText}
                    sx={{
                      textAlign: 'center',
                      maxWidth: [null, '100%'],
                      fontSize:
                        fontSizes[Number(text?.expired?.fontSize)] ||
                        fontSizes[2],
                      color: [
                        text?.expired?.expiredColorMb || '#fff',
                        text?.expired?.expiredColorDt || '#fff',
                      ],
                      margin: 0,
                      '& h1': {
                        margin: 0,
                        fontSize:
                          fontSizes[Number(text?.expired?.fontSize)] ||
                          fontSizes[2],
                      },
                      '& h2': {
                        margin: 0,
                        fontSize:
                          fontSizes[Number(text?.expired?.fontSize)] ||
                          fontSizes[2],
                      },
                      '& p': {
                        margin: 0,
                        fontSize:
                          fontSizes[Number(text?.expired?.fontSize)] ||
                          fontSizes[2],
                      },
                    }}
                  />
                </Flex>
              );
            }

            return (
              <Flex
                className="timer"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  fontSize: '24px',
                  backgroundColor: background || '#333',
                  padding: '10px 16px',
                  borderRadius: '6px',
                  gap: '10px',
                  color,
                  border: '1px solid',
                  borderColor: border || ' #333',
                }}
              >
                <TimeUnitDisplay
                  timeValue={days}
                  label="Days"
                  showColon
                  colonColor={colon}
                />

                <TimeUnitDisplay
                  timeValue={hours}
                  label="Hours"
                  showColon
                  colonColor={colon}
                />

                <TimeUnitDisplay
                  timeValue={minutes}
                  label="Mins"
                  showColon
                  colonColor={colon}
                />

                <TimeUnitDisplay timeValue={seconds} label="Sec" />
              </Flex>
            );
          }}
        />
      </Flex>
    </Section>
  );
};

CountdownTimerBanner.displayName = 'CountdownTimerBanner';
CountdownTimerBanner.Schema = Schema;
