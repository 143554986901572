import { Container } from 'theme-ui';

import { Section } from '@snippets';
import { convertAlignToFlex, withInView } from '@utils';

import { Schema } from './Html.schema';
import { themed } from './Html.theme';

export const Html = withInView(
  themed(({ theme, cms }) => {
    const { html, section, content } = cms;

    return (
      <Section section={section} cms={cms}>
        <Container
          data-comp={Html.displayName}
          sx={{
            ...theme.html,
            display: 'flex',
            flexDirection: 'column',
            textAlign: content?.textAlign,
          }}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </Section>
    );
  }),
  { triggerOnce: true }
);

Html.displayName = 'Html';
Html.Schema = Schema;
