import { color, align } from '@settings/common';

const heading = ({ color, align }) => ({
  label: 'Heading settings',
  name: 'heading',
  description: 'Heading text, position and color',
  component: 'group',
  fields: [
    {
      name: 'headingText',
      component: 'markdown',
      label: 'Heading',
      description:
        'Ensure heading is "h1" by starting with "# " e.g. "# Shop All". Text below will become large and bold to indicate "h1".',
    },
    {
      name: 'headingAlignmentMb',
      label: 'Heading alignment (mobile)',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: align.options,
    },
    {
      component: 'select',
      name: 'headingColorMb',
      label: 'Text color (mobile)',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
    {
      name: 'headingAlignmentDt',
      label: 'Heading alignment (desktop)',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: align.options,
    },
    {
      component: 'select',
      name: 'headingColorDt',
      label: 'Text color (tablet & desktop)',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
  ],
  defaultValue: {
    headingText: '# Welcome to Storefront **Backpack**',
    headingAlignmentMb: 'center',
    headingColorMb: '',
    headingAlignmentDt: 'center',
    headingColorDt: '',
  },
});

const superheading = ({ color, align }) => ({
  label: 'SuperHeading settings',
  name: 'superheading',
  description: 'SuperHeading text, position and color',
  component: 'group',
  fields: [
    {
      name: 'superheadingText',
      component: 'markdown',
      label: 'SuperHeading',
      description: 'Heading above the main heading.',
    },
    {
      name: 'superheadingAlignmentMb',
      label: 'SuperHeading alignment (mobile)',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: align.options,
    },
    {
      component: 'select',
      name: 'superheadingColorMb',
      label: 'Text color (mobile)',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
    {
      name: 'superheadingAlignmentDt',
      label: 'SuperHeading alignment (desktop)',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: align.options,
    },
    {
      component: 'select',
      name: 'superheadingColorDt',
      label: 'Text color (tablet & desktop)',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
  ],
  defaultValue: {
    superheadingText: ' Backpack Storefronts',
    superheadingAlignmentMb: 'center',
    superheadingColorMb: '',
    superheadingAlignmentDt: 'center',
    superheadingColorDt: '',
  },
});

const subheading = ({ color, align }) => ({
  label: 'SubHeading settings',
  name: 'subheading',
  description: 'SubHeading text, position and color',
  component: 'group',
  fields: [
    {
      name: 'subheadingText',
      component: 'markdown',
      label: 'SubHeading',
    },
    {
      name: 'subheadingAlignmentMb',
      label: 'subheading alignment (mobile)',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: align.options,
    },
    {
      component: 'select',
      name: 'subheadingColorMb',
      label: 'Text color (mobile)',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
    {
      name: 'subheadingAlignmentDt',
      label: 'subheading alignment (desktop)',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: align.options,
    },
    {
      component: 'select',
      name: 'subheadingColorDt',
      label: 'Text color (tablet & desktop)',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
  ],
  defaultValue: {
    subheadingPlacement: 'below-logo',
    subheadingText: 'Manage content directly within the customizer.',
    subheadingAlignmentMb: 'center',
    subheadingColorDt: '',
    subheadingAlignmentDt: 'center',
    subheadingColorMb: '',
  },
});

export default {
  label: 'Footer settings',
  name: 'footer',
  component: 'group',
  fields: [
    {
      label: 'Image settings',
      name: 'imageSettings',
      component: 'group',
      fields: [
        {
          label: 'enable image',
          name: 'enable',
          component: 'toggle',
          description: 'Enable or disable the image',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: false,
        },
        {
          label: 'image',
          name: 'image',
          component: 'image',
        },
        {
          label: 'image height desktop',
          name: 'imageHeight',
          component: 'number',
          defaultValue: 300,
        },
        {
          label: 'image height mobile',
          name: 'imageHeightMb',
          component: 'number',
          defaultValue: 250,
        },
        heading({ color, align }),
        superheading({ color, align }),
        subheading({ color, align }),
      ],
    },
    {
      label: 'Email marketing',
      name: 'emailSignUp',
      component: 'group',
      fields: [
        {
          label: 'Heading',
          name: 'heading',
          component: 'text',
        },
        {
          label: 'Subtext',
          name: 'subtext',
          component: 'textarea',
        },
        {
          label: 'Newsletter Category Subscription',
          name: 'categorySubscription',
          description: 'Categories to subscribe to (max. 3)',
          component: 'group-list',
          itemProps: {
            label: '{{item.category}}',
          },
          defaultItem: {
            category: 'Category',
          },
          fields: [
            {
              label: 'Category',
              name: 'category',
              component: 'text',
            },
          ],
          defaultValue: [
            {
              category: 'Men',
            },
            {
              category: 'Women',
            },
            {
              category: 'Both',
            },
          ],
        },
        {
          label: 'input placeholder',
          name: 'inputPlaceholder',
          component: 'text',
          defaultValue: 'Your email address',
        },
      ],
      defaultValue: {
        heading: 'get 15% off your first purchase',
        subtext: '',
      },
    },
    {
      label: 'Footer menu',
      name: 'menu',
      component: 'group-list',
      itemProps: {
        label: '{{item.name}}',
      },
      defaultItem: {
        name: 'Shop',
        subMenuItems: [
          {
            cta: {
              text: 'Accessories',
              url: '/collections/all',
            },
          },
          {
            cta: {
              text: 'Bundles',
              url: '/collections/bundles',
            },
          },
          {
            cta: {
              text: 'Shop All',
              url: '/collections/all',
            },
          },
        ],
      },
      fields: [
        {
          label: 'Menu item name',
          name: 'name',
          component: 'text',
        },
        {
          label: 'Sub menu',
          name: 'subMenuItems',
          component: 'group-list',
          itemProps: {
            label: '{{item.cta.text}}',
          },
          defaultItem: {
            cta: {
              text: 'Shop All',
              url: '/collections/all',
            },
          },
          fields: [
            {
              label: 'Link',
              name: 'cta',
              component: 'link',
            },
          ],
        },
      ],
      defaultValue: [
        {
          name: 'Shop',
          subMenuItems: [
            {
              cta: {
                text: 'Accessories',
                url: '/collections/all',
              },
            },
            {
              cta: {
                text: 'Bundles',
                url: '/collections/bundles',
              },
            },
            {
              cta: {
                text: 'Shop All',
                url: '/collections/all',
              },
            },
          ],
        },
        {
          name: 'Company',
          subMenuItems: [
            {
              cta: {
                text: 'About Us',
                url: '/pages/about-us',
              },
            },
            {
              cta: {
                text: 'Rewards',
                url: '/pages/rewards',
              },
            },
            {
              cta: {
                text: 'Blog',
                url: '/blogs/blog',
              },
            },
          ],
        },
        {
          name: 'Contact',
          subMenuItems: [
            {
              cta: {
                text: 'Contact Us',
                url: '/pages/contact-us',
              },
            },
            {
              cta: {
                text: 'Return Policy',
                url: '/pages/return-policy',
              },
            },
            {
              cta: {
                text: 'Store Locator',
                url: '/pages/store-locator',
              },
            },
          ],
        },
      ],
    },
    {
      label: 'Legal links',
      name: 'legalLinks',
      component: 'group-list',
      itemProps: {
        label: '{{item.cta.text}}',
      },
      defaultItem: {
        cta: {
          text: 'Privacy Policy',
          url: '/pages/privacy-policy',
        },
      },
      fields: [
        {
          label: 'Link',
          name: 'cta',
          component: 'link',
        },
      ],
      defaultValue: [
        {
          cta: {
            text: 'Privacy Policy',
            url: '/pages/privacy-policy',
          },
        },
        {
          cta: {
            text: 'Terms & Conditions',
            url: '/pages/terms-conditions',
          },
        },
      ],
    },
    {
      label: 'Site copyright notice',
      name: 'copyrightNotice',
      component: 'text',
      defaultValue: 'All Rights Reserved',
    },
  ],
};
