import { align } from '../common';

export default {
  label: 'Banner Settings',
  name: 'banner',
  component: 'group',
  fields: [
    {
      label: 'Alt',
      name: 'imageAlt',
      component: 'text',
      description: 'Brief description of image',
    },
    {
      label: 'Background Image (mobile)',
      name: 'imageMobile',
      component: 'image',
    },
    {
      label: 'Background Image (tablet/desktop)',
      name: 'imageDesktop',
      component: 'image',
    },
    {
      label: 'Heading',
      name: 'heading',
      component: 'text',
    },
    {
      label: 'Subheading',
      name: 'subheading',
      component: 'text',
    },
    {
      label: 'Link (optional)',
      name: 'link',
      component: 'link',
      description: 'Makes banner a clickable link',
    },
    {
      label: 'Text Color',
      name: 'textColor',
      component: 'color',
      defaultValue: '#000000',
    },
    {
      label: 'Text Alignment (mobile)',
      name: 'textAlignMobile',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: align.options,
      defaultValue: 'center',
    },
    {
      label: 'Text Alignment (tablet/desktop)',
      name: 'textAlignDesktop',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: align.options,
      defaultValue: 'center',
    },
  ],
};
